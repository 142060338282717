<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-col cols="12" lg="4">
			<v-card class="mx-auto">
				<v-card-title>Informações</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<div class="my-3">
						<v-icon class="mr-2">mdi-identifier</v-icon>
						<span class="font-weight-bold">Nº Pedido:</span>
						{{dados.idpedido}}
					</div>
					<div class="my-3" v-if="dados.cliente">
						<v-icon class="mr-2">mdi-account</v-icon>
						<span class="font-weight-bold">Cliente:</span>
						{{dados.cliente.nome}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-office-building</v-icon>
						<span class="font-weight-bold">Filial:</span>
						{{dados.filial}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-calendar</v-icon>
						<span class="font-weight-bold">Data do orçamento:</span>
						{{dados.dtpedido}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-cash</v-icon>
						<span class="font-weight-bold">Valor:</span>
						{{dados.valortotal | formataDinheiro}}
					</div>
					<div class="my-3">
						<v-icon class="mr-2">mdi-note-text-outline</v-icon>
						<span class="font-weight-bold">Observação:</span>
						{{dados.observacao}}
					</div>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-spacer />
					<v-btn
						v-if="permissao('orcamento_cancelar')"
						color="error"
						elevation="0"
						@click="cancelarOrcamento()"
					>Cancelar</v-btn>
				</v-card-actions>
			</v-card>
		</v-col>
		<v-col cols="12" lg="8">
			<v-row>
				<v-col>
					<Table
						:pgLimitGet="10"
						backendDirectory="pedido/produto/listar"
						:busca="{
                            idpedido: parseInt(idorcamento),
                        }"
						:filter="false"
						:headers="{
                            idsubproduto: {nome: 'ID', tipo: 'texto'},
                            produto: {nome: 'Produto', tipo: 'texto'},
                            quantidade: {nome: 'Quantidade', tipo: 'texto', class: 'text-center'},
                            valortotal: {nome: 'Valor total', tipo: 'dinheiro'},
                            vendedor: {nome: 'Vendedor', tipo: 'texto'},
                            tipoentrega: {nome: 'Tipo entrega', tipo: 'texto'},
                            localretirada: {nome: 'Local retirada', tipo: 'texto'},
                            dtpreventrega: {nome: 'Data previsão entrega', tipo: 'texto'},
                        }"
					>
						<v-col>Produtos</v-col>
						<v-col cols="auto">
							<v-btn
								color="primary"
								elevation="0"
								@click="$router.push({
									name: 'VendaPedidoFaturar',
									params: {
										idorcamento: parseInt(idorcamento),
									},
								})"
							>Exportar</v-btn>
						</v-col>
					</Table>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import Table from "../Widgets/Table";
import { mapState } from "vuex";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
	components: { Table, CardDialog },
	name: "VendaOrcamentoGet",
	props: ["idorcamento"],
	data: () => ({
		carregando: false,
		dados: {},
		produtos: {},
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		get() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}orcamento/get`, {
					idpedido: parseInt(this.idorcamento),
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		cancelarOrcamento() {
			return axios
				.post(`${this.backendUrl}venda/pedido/cancelar`, {
					idpedido: parseInt(this.idorcamento),
					idempresa: parseInt(this.dados.idfilial),
				})
				.then((res) => {
					if (res.data) {
						this.dialogErro.title = "Sucesso";
						this.dialogErro.msg =
							"Orçamento cancelado com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
						setTimeout(() => {
							this.$router.push(`/venda/orcamento`);
						}, 2000);
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível exluir o orçamento!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível exluir o orçamento!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
	},
	created() {
		this.get();
	},
};
</script>

<style scoped>
</style>